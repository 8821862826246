import Axios from "axios"
import WeditApi from "../../common/WeditApi"
import SettingsModel from "../model/SettingsModel"
import {retry} from "../../common/Retry"

class SettingsApi {
  public static URL = `${WeditApi.URL}/settings`

  public static async getSettings(): Promise<SettingsModel> {
    return SettingsApi.getSettingsForClient(WeditApi.CLIENT_UUID)
  }

  public static async getSettingsForClient(clientUuid: string): Promise<SettingsModel> {
    return retry(async () => {
      const x = await Axios.get(`${this.URL}/${clientUuid}`, {timeout: 5000})
      return x.data
    })
  }
}

export default SettingsApi
