import {
  createStyles, Grid, Theme, withStyles, WithStyles,
} from '@material-ui/core'
import * as React from 'react'
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom'
import GuestListTabContainer from '../organizerView/components/GuestListTabContainer'
import GuestContentContainer from '../guestView/components/GuestContentContainer'
import LoginContainer, {LoginState} from '../login/components/LoginContainer'
import {GuestPartyModelNew} from '../organizerView/model/NewGuestModel'
import {StyleRules} from '@material-ui/core/styles'
import AppLoading from './AppLoading'
import ErrorPage from '../common/components/ErrorPage'
import SettingsApi from '../settings/service/SettingsApi'
import SettingsModel from '../settings/model/SettingsModel'
import AppContext from '../settings/model/Context'
import Alert from '../common/components/Alert'
import ContentService from '../common/ContentService'
import GuestLoginPage from '../login/components/GuestLoginPage'

interface AppContentState {
  isLoading: boolean;
  hasError: boolean;
  settings: SettingsModel;
  loggedInUser: LoginState;
  token: string;
  loggedInGuest: GuestPartyModelNew | null;
}

class AppContentInner extends React.Component<WithStyles<typeof styles>, AppContentState> {
  public constructor(props: WithStyles<typeof styles>) {
    super(props)
    this.state = {
      isLoading: true,
      hasError: false,
      loggedInUser: LoginState.NotLoggedIn,
      token: '',
      loggedInGuest: null,
      settings: null
    }
  }

  // TODO: Migrate this logic to the constructor
  UNSAFE_componentWillMount(): void {
    SettingsApi.getSettings()
      .then(x => {
        this.setState({settings: x})
        document.title = `${x.names.bride} & ${x.names.groom}`
      })
      .catch(() => this.setState({hasError: true}))
      .finally(() => this.setState({isLoading: false}))
  }

  public updateLoginState = (state: LoginState, token: string, loggedInGuest: GuestPartyModelNew | null): void => {
    // TODO: This method can make token optional as never used for guest
    this.setState({loggedInUser: state, token, loggedInGuest})
  }

  public updateGuestHasResponded = (): void => {
    const guest = this.state.loggedInGuest ? {...this.state.loggedInGuest, responded: true} : null
    this.setState({loggedInGuest: guest})
  }

  public renderRoutes(): JSX.Element {
    return (
      <Switch>
        <Route
          path='/'
          exact={true}
          render={(): JSX.Element => (
            <LoginContainer
              loginState={this.state.loggedInUser}
              updateLoginState={this.updateLoginState}
              isAdmin={false}
            />
          )}
        />
        <Route
          path='/admin'
          exact={true}
          render={(): JSX.Element => (
            <LoginContainer
              loginState={this.state.loggedInUser}
              updateLoginState={this.updateLoginState}
              isAdmin
            />
          )}
        />
        <Route path='/rsvp/:clientId'
          exact
          render={routerProps => (<GuestLoginPage clientId={routerProps.match.params.clientId} />)}
        />
        {this.state.loggedInUser === LoginState.NotLoggedIn && <Redirect to='/' />}
        {this.state.loggedInUser === LoginState.BrideGroom && (
          <Route
            path='/guestList'
            render={(): JSX.Element => <GuestListTabContainer token={this.state.token} />}
          />
        )}
        {this.state.loggedInUser === LoginState.Guest && (
          <Route
            path='/guest'
            render={(): JSX.Element => <GuestContentContainer
              // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
              guest={this.state.loggedInGuest!!}
              hasResponded={this.updateGuestHasResponded} />}
          />
        )}
      </Switch>
    )
  }


  public render(): JSX.Element {
    const {classes} = this.props
    let mainContent
    if (this.state.isLoading) {
      mainContent = (<AppLoading />)
    } else if (this.state.hasError) {
      mainContent = (<ErrorPage />)
    } else {
      mainContent = this.renderRoutes()
    }
    const context = {
      settings: this.state.settings,
      token: this.state.token
    }
    return (
      <React.Fragment>
        <BrowserRouter>
          <Grid container justify='center'>
            <main className={classes.content}>
              {this.state.settings && this.state.settings.loginPageData.alertText && (
                <div className={classes.alert}>
                  <Alert heading="Important Information">
                    {ContentService.parseContent(this.state.settings.loginPageData.alertText)}
                  </Alert>
                </div>
              )}
              <AppContext.Provider value={context}>
                {mainContent}
              </AppContext.Provider>
            </main>
          </Grid>
        </BrowserRouter>
      </React.Fragment>
    )
  }
}

const styles = (theme: Theme): StyleRules => createStyles({
  toolbar: theme.mixins.toolbar,
  content: {
    padding: theme.spacing() * 3,
  },
  alert: {
    maxWidth: '80%',
    textAlign: 'center',
    margin: '0 auto',
    paddingBottom: '10px'
  }
})
const AppContent = withStyles(styles)(AppContentInner)

export default AppContent
