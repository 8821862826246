import * as React from 'react'
import SettingsModel from '../../settings/model/SettingsModel'
import AppLoading from '../../components/AppLoading'
import SettingsApi from '../../settings/service/SettingsApi'
import LoadingState from '../../common/LoadingState'
import ErrorPage from '../../common/components/ErrorPage'

interface GuestLoginPageProps {
  clientId: string;
}

interface GuestLoginPageState {
  settings: SettingsModel;
  loadingState: LoadingState;
}

export default class GuestLoginPage extends React.Component<GuestLoginPageProps, GuestLoginPageState> {
  public constructor(props: GuestLoginPageProps) {
    super(props)
    this.state = {
      settings: null,
      loadingState: LoadingState.LOADING
    }

    SettingsApi.getSettingsForClient(props.clientId)
      .then(x => this.setState({settings: x, loadingState: LoadingState.LOADED}))
      .catch(() => this.setState({loadingState: LoadingState.ERROR}))
  }

  public render(): React.ReactNode {
    if (this.state.loadingState == LoadingState.LOADING) {
      return <AppLoading />
    } else if (this.state.loadingState == LoadingState.ERROR) {
      return <ErrorPage />
    } else {
      return null
    }
  }
}
